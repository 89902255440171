<template>
  <el-dialog
    title="白名单设置"
    :visible="dialogVisible"
    width="450px"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="90px"
      class="form"
      :rules="formRules"
    >
      <el-form-item
        v-for="f in eidtableFormItems"
        :key="f.prop"
        :label="f.label"
        :prop="f.prop"
      >
        <el-input
          v-model="form[f.prop]"
          :placeholder="f.placeholder"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确认保存</el-button>
      <el-button @click="close">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      form: this.initForm(),
      eidtableFormItems: [
        {
          prop: "white_list",
          label: "白名单设置",
          placeholder: "设备唯一标识 以回车分隔",
        },
      ],
      formRules: {}
    };
  },
  methods: {
    ...mapActions([
      "updateApkData"
    ]),
    show(editData) {
      this.dialogVisible = true;
      if (editData) {
        this.form = editData;
      } else {
        this.form = this.initForm();
      }
    },
    initForm() {
      return {
        id: null,
        white_list: ''
      };
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$changeLoadingState(true);
          const ret = await this.updateApkData(this.form);
          this.$changeLoadingState(false);
          if (ret && ret.status === 'success') {
            this.close();
            this.$emit('confirm');
          } else {
            this.$message.error(ret && ret.msg);
          }
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='scss'>
.form {
  background-color: #fff;
  .el-cascader--small {
    width: 100%;
  }
  .el-form-item__content {
    .el-select {
      display: block;
    }
  }
}
</style>