<script>
export default {
    data() {
        return {
            form: {
                status: ''
            }
        };
    },
    methods: {
        onSubmit() {
            this.$emit('search');
        },
        getValue() {
            return Object.assign({}, this.form);
        }
    }
};
</script>
<template>
    <el-form :inline="true" size="mini" :model="form" class="demo-form-inline">
        <el-form-item label="状态">
            <el-select v-model="form.status" clearable placeholder="请选择">
            <el-option label="启用" value="true"></el-option>
            <el-option label="停用" value="false"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
    </el-form>
</template>
